*{
  box-sizing: border-box;
}

.App {
  text-align: center;
}


.output {
  background-color: #B0D7FF;
  color: black;
  height: 600px;
  width: 400px;
  margin: 0 auto;
  box-shadow: 20px 20px 20px 5px black;
  text-indent: 1;
  overflow: scroll;
}

.submit-button {
  height: 45px;
  width: 1000px;
  margin-top: 1em;
}

.module {
  display:flex;
  margin: 0 auto;
  gap: 1em;
  margin-top: 3em;
}

.dashboard {
  display: block;
  margin: 0 auto;
  margin-right: .5em ;
  margin-left: 300px;
  width: 70%;
  padding: 50px 50px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #fff; 
  box-shadow: 20px 20px 20px 5px black;
}

.count {
  max-height: 250px;
  border: none;

}

.donut {
  height: 500px;
  width: 350px;
  border: none;
  margin: 0 auto;
}

.welcome {
	color: #282c34;
  margin: 0 auto;
  margin-top: 1.5em;
  font-size: 40px;
  text-align: center;
  margin-bottom: 4em;
  position: sticky;
}




.users_chart {
  border: none;
  height: 275px;
}


body {
  width: 100%;
  background-color: #282c34;
  background-position:center;
  background-size: cover;
  position: relative;
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
}

.nav-links{
  text-align:right; /*text will be on the right side*/

}

.nav-links ul li{
  list-style: none; /*so we can get rid of the bullet points*/
  display: inline-block; /*so the home headings will all come horizontally*/
  padding: 8px 12px;
  position: relative; /**/

}

.nav-links ul li a:hover{
  color: #B0D7FF;

}

.nav-links ul li a{ /*add a for the anchor tag, this is for the home, courses, etc*/
  color: #282c34;
  font-weight: bold;
  text-decoration: none;
  font-size: 13px;

}

.nav-links ul li::after{ /*this should give like a red line underneath the home, courses, login*/
  content: '';
  width: 0%;
  height:2px;
  background: #282c34;
  display:block;
  margin: auto;
  transition: 0.5s;
}

.nav-links ul li:hover::after{ /*sleek hover effect - red line should show up*/
  width: 100%;
}

.text-box{
  width: 90%;
  color: #B0D7FF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  list-style-type: none;
}

.text-box h1{
  font-size: 100px;
}

.text-box p{
  margin: 10px 0 40px;
  font-size: 14px;
  color: #fff;
}

.hero-btn{ /*this is for the LOGIN BUTTON, the button should be lower center of screen with box around the login button*/
  display: inline-block;
  text-decoration:none;
  color:#fff;
  border: 1px solid #fff;
  padding: 12px 34px;
  font-size: 13px;
  background: transparent;
  position: relative;
  cursor: pointer;
  margin-bottom: 1em;
  margin-top: 1em ;
}

.hero-btn:hover{
  border: 1px solid #B0D7FF;
  background: #B0D7FF;
}

.about-btn {
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  color: #B0D7FF;
  font-weight: bold;
}

.about-btn:hover {
  background-color: #282c34;
}

.contact-btn {
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  color: #B0D7FF;
  font-weight: bold;
}

.about-desc {
  margin: 0 auto;
  text-align: center;
  max-width: 1200px;
  margin-top: 4em;
  margin-bottom: 4.5em;
}

.poc-desc {
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
}

.about-back-btn {
  text-decoration:none;
  color:#fff;
  border: 1px solid #fff;
  padding: 8px 10px 10px 10px;
  font-size: 13px;
  background: transparent;
  position: relative;
  cursor: pointer;
  max-width: 5em;
  margin: 0 auto;
  margin-top: 3em;
}

.about-back-btn:hover{
  border: 1px solid #B0D7FF;
  background: #B0D7FF;
}

.module-loading{
  font-size: 40px;
  margin: 0 auto;
}

.submits {
  background-color: #fff;
  margin: 0 auto;
}

.profile-page {
  margin: 0 auto;
  text-align: center;
  margin-top: 3em;
}

.signup-form {
  margin: 0 auto;
  max-width: 200px;
  gap: 1em;
  display: list-item;
}

.login-form {
  margin: 0 auto;
  max-width: 200px;
  gap: 1em;
  display: list-item;
}

.table {
  background-color: #B0D7FF;
  color: black;
  margin: 0 auto;
  margin-bottom: 2.5em;
  width: 1000px;
  max-width: 1400px;
  border-collapse: collapse;
  box-shadow: 20px 20px 20px 5px;
  list-style: none;
}

.table-thead {
  background-color: #fff;
  margin: 0 auto;
  width: 1800px;
}

.password__input {
   margin-bottom: 1em;
}

.Rewards_section {
  background-color: #fff;
  margin: 0 auto;
  width: 400px;
}

.Rewards_list {
  color: black;
  width: 200px;
}

.Rewards_Label {
    color: black;
}

ul.a {
  list-style-type: circle;
}



.Module_Title {
  display: flex;
  justify-content: center;
  padding: 20px 500px;
  background-color: #B0D7FF;
  max-width: 1000px;
  margin: 0 auto;
  color: #282c34;
  box-shadow: 20px 20px 20px 5px black;
  margin-bottom: 1em;
  margin-top: 1em;
}

.language {
  display: flex;
  justify-content: center;
  padding: 20px 500px;
  background-color: #B0D7FF;
  color: #282c34;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 20px 20px 20px 5px black;

}

.table__row:hover {
    background-color: #282c34;
    border-bottom: 0px;
}

td {
  padding: 12px;
  max-width: 150px;
}

tr{
  height: 50px;
  border-bottom:rgba(0,0,0,.05) 1px solid;
}

.No__Mods {
  background-color: antiquewhite;
}



#name {
  font-weight: bold;
  color: #282c34;
}

h1 {
  color: #282c34;
}

.title {
  box-shadow: 20px 20px 20px 5px black;
  font-size:60px;
  background-color: #fff;
  padding: 10px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  text-align: center;
}

.password__input {
  border-radius: 5px;
}

.username__input {
  border-radius: 5px;
}

.username {
  color: #282c34;
}

.password {
  color: #282c34;
}

.lesson__options {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  gap: 400px;
}

.prev__language {
  background-color: #B0D7FF;
  color: black;
  margin: 0 auto;
  text-align: center;
  width: 70%;
  max-width: 1400px;
  border-collapse: collapse;
  margin-top: 1em;
  box-shadow: 20px 20px 20px 5px;

}

.prev__table {
  background-color: #B0D7FF;
  color: black;
  margin: 0 auto;
  margin-bottom: 2.5em;
  width: 70%;
  max-width: 1400px;
  border-collapse: collapse;
  box-shadow: 20px 20px 20px 5px;
}

.quicklesson__table {
  position:absolute;
  top: 425px;
  left: 275px;
  width: 200px;
  height: 50%;
  margin-left: -250px;
  overflow-y: 1;
  background-color: #B0D7FF;
  color: black;
  border-collapse: collapse;
  box-shadow: 20px 20px 20px 5px;
}

@media screen and (max-width: 600px) {
  .homescreen {
    display: block;
  }
}

.addpost_textbox {
  background-color: #B0D7FF;
  text-align: center;
  margin: 0 auto;
  width: 70% ;
  box-shadow: 20px 20px 20px 5px;
  color: black;
  list-style: none;
  margin-bottom: 1em;
}

.frontend__output {
  background-color: #fff;
  height: 600px;
}

.logo__link {
  color: #282c34;
}

.table__row__completed{
  color: #fff;
  background-color: #282c34;
}